<template>
  <b-card title="Viết bài - Thành tựu nghiên cứu khoa học">
    <b-form @submit.prevent="handleSubmit()">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Tiêu đề"
            label-cols-md="2"
            label-for="h-first-name"
          >
            <b-form-input
              id="h-first-name"
              placeholder=""
              v-model="postData.title"
              :class="{ 'is-invalid' : isInvalid(postData.title)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Mô tả/Tóm tắt"
            label-cols-md="2"
            label-for="post-summary"
          >
            <b-form-textarea
              id="post-summary"
              placeholder=""
              v-model="postData.summary"
              :class="{ 'is-invalid' : isInvalid(postData.summary)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Link sản phẩm"
            label-cols-md="2"
            label-for="h-first-name"
          >
            <b-form-input
              id="h-product-link"
              placeholder=""
              v-model="postData.product_url"
              :class="{ 'is-invalid' : isInvalid(postData.product_url)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Tác giả"
            label-cols-md="2"
            label-for="h-first-name"
          >
            <b-form-input
              id="h-author"
              placeholder="Theo TT Nghiên cứu và phát triển Rau hoa quả"
              v-model="postData.author"
              :class="{ 'is-invalid' : isInvalid(postData.author)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Danh mục"
            label-cols-md="2"
            label-for="h-category"
          >
            <b-form-select
              :options="categoryOptions"
              class="mt-1"
              size="sm"
              v-model="postData.category_id"
              :class="{ 'is-invalid' : isInvalid(postData.category_id)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Nội dung"
            label-cols-md="2"
            label-for="post-summary"
          >
            <b-form-textarea
              id="post-content"
              placeholder=""
              v-model="postData.content"
              :class="{ 'is-invalid' : isInvalid(postData.content)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Hình ảnh đại diện"
            label-cols-md="2"
            label-for="h-images"
          >
            <b-form-input
              id="product-avatar"
              v-model="postData.avatar"
              :class="{ 'is-invalid' : isInvalid(postData.avatar)}"
              placeholder=""
              rows="5"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" v-if="postData.avatar">
          <b-form-group
            label="Xem trước hình ảnh"
            label-cols-md="2"
            label-for="h-image-preview "
          >
            <b-img class="preview-image" :src="postData.avatar" v-viewer />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="justify-content-end">
        <b-button
          class="mr-1"
          type="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
        >
          Lưu thông tin
        </b-button>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
  import {
    BButton,
    BCard,
    BCol,
    BForm,
    BImg,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BRow
  } from 'bootstrap-vue'
  import Ripple from "vue-ripple-directive";
  import ImageResize from 'quill-image-resize-module';
  import ToastificationContent from '../../../../@core/components/toastification/ToastificationContent'

  export default {
    name: "CategoryAdd",
    components: {
      BImg, BButton, BFormTextarea, BRow, BCol, BCard, BForm, BFormInput, BFormGroup, BFormSelect
    },
    directives: {
      Ripple
    },
    data() {
      return {
        postData: {
          page_id: "NCKH",
          is_research_achievements: true,
          title: '',
          summary: '',
          product_url: '',
          author: '',
          category_id: '',
          content: '',
          avatar: ''
        },
        isValidate: false,
        categories: [],
      }
    },
    computed: {
      categoryOptions() {
        return this.categories.map((e) => {
          return {
            value: e.id,
            text: e.name
          }
        })
      }
    },
    created() {
      this.$http.get('/search/category?page_id=TT')
          .then(res => {
            this.categories = res.data.data
          })
    },
    methods: {
      isInvalid(val) {
        return this.isValidate && val.length === 0
      },
      onEditorBlur(quill) {
        console.log('editor blur!', quill)
      },
      onEditorFocus(quill) {
        console.log('editor focus!', quill)
      },
      onEditorReady(quill) {
        console.log('editor ready!', quill)
        console.log(ImageResize)
        window.Quill.register('modules/imageResize', ImageResize)
      },
      onEditorChange({quill, html, text}) {
        console.log('editor change!', quill, html, text)
        this.content = html
      },
      handleSubmit () {
        this.isValidate = true
        if (
            this.postData.title.length &&
            this.postData.summary.length &&
            this.postData.product_url.length &&
            this.postData.author.length &&
            this.postData.category_id &&
            this.postData.content.length &&
            this.postData.avatar.length)
        {
          this.handleRequest()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              icon: 'BellIcon',
              text: 'Vui lòng nhập đầy đủ thông tin!',
              variant: 'danger',
            },
          })
        }
      },
      async handleRequest() {
        this.$http.post('/posts', this.postData)
            .then(response => {
              console.log(response.data)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Thông báo',
                  icon: 'BellIcon',
                  text: 'Cập nhật thành công👋',
                  variant: 'success',
                },
              })
              this.$router.push('/manage/nghien-cuu-khoa-hoc/list')
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Thông báo',
                  icon: 'BellIcon',
                  text: 'Đã xảy ra lỗi. Vui lòng thử lại sau!',
                  variant: 'danger',
                },
              })
            })
      }
    }
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/quill.scss';

  .ql-container {
    min-height: 500px;
  }
</style>
